.main-wrapper {
    width: 100%;
    margin: 50px auto;
}

body {
    height: inherit;
    display: flex;
    flex-direction: column;
}

a {
    color: #50585f;
    text-decoration: none;
}

a:hover {
    color: #383e44;
}

div.container {
    display: flex;
    flex: auto;
    flex-direction: column;
    max-height: 100%;
}


div.header h1 {
    margin-top: 0.8rem;
    margin-bottom: 0.5rem;
    font-weight: 200;
    font-size: 1.6em;
    letter-spacing: 0.1rem;
    text-transform: uppercase;
}

@media (min-width: 62em) {
    div.header h1 {
        font-size: 1.9em;
        letter-spacing: 0.2rem;
    }
}

div.header h2 {
    font-size: 1.1em;
    font-weight: 400;
    color: #cfd7de;
    max-width: 30rem;
    margin: auto;
}

div.item {
    display: flex;
    min-height: 500px;
    flex: auto;
}

#timeline {
    position: relative;
    display: table;
    height: 100%;
    margin-left: 0;
    width: 100%;
    margin-top: 1rem;
}

#timeline .line:after {
    content: '';
    border: 1px dashed rgba(1, 199, 177, 0);
    margin: 0 0 0 7px;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
}

.empty-block {
    width: 100%;
}

.empty-data-head {
    width: 70%;
    min-height: 20px;
    margin-top: 4px;
    background-color: rgba(236, 236, 236, 0.34901960784313724);
}

.empty-data-body {
    width: 100%;
    margin-top: 7px;
    min-height: 60px;
    background-color: rgba(236, 236, 236, 0.34901960784313724);
}

/*#timeline #Assigned:after {*/
/*    border: 1px dashed red !important;*/
/*}*/

small {
    font-size: 70%;
}

.badge {
    padding: 0.5rem .50em;
    height: 25px;
}

#timeline h3 {
    top: 5rem;
    color: #888;
    margin: 0;
    font-size: 1em;
    font-weight: 400;
}

@media (min-width: 62em) {
    #timeline h3 {
        font-size: 1.1em;
    }
}

#Confirmed {
    border-left: 1px dashed rgba(1, 199, 177, 0) !important;
}

.line {
    border-left: 1px dashed rgba(1, 199, 177, 0.5);
    padding-bottom: 4em;
    margin-left: 6px;
}

.isActive {
    border-left: 1px dashed rgba(1, 199, 177, 0.5) !important;
}

.isInActive {
    border-left: 1px dashed rgba(185, 185, 185, 0.5) !important;
}


.isActive-step-0::after, .isActive-step-1::after, .isActive-step-2::after, .isActive-step-3::after {
    color: #FFF !important;
}

.isInActive-step-0::after, .isInActive-step-1::after, .isInActive-step-2::after, .isInActive-step-3::after {
    color: #c9d1da !important;
}

#step-0::after {
    content: '1' !important;
}

#step-1::after {
    content: '2' !important;
}

#step-2::after {
    content: '3' !important;
}

#step-3::after {
    content: '4' !important;
}

#timeline section.year {
    position: relative;
}

#timeline section.year:first-child section {
    margin-top: -1.3em;
    padding-bottom: 25px;
}

#timeline section.year section {
    position: relative;
}

#timeline section.year section h4 {
    position: absolute;
    bottom: 0;
    font-size: 0.9em;
    font-weight: 400;
    line-height: 1.2em;
    margin: 0;
    padding: 0 0 0 89px;
    color: #c5c5c5;
}

@media (min-width: 62em) {
    #timeline section.year section h4 {
        font-size: 1em;
    }
}

#timeline section.year section ul {
    list-style-type: none;
    padding: 0 0 0 45px !important;
    /*margin: -1.35rem 0 1em;*/
    max-width: 32rem;
    font-size: 1em;
}

@media (min-width: 62em) {
    #timeline section.year section ul {
        font-size: 1.1em;
        padding: 0 0 0 81px;
    }
}

.border > #timeline div:after {
    border: none !important;
}

#timeline section.year section ul:last-child {
    margin-bottom: 0;
}

#timeline section.year section ul:first-of-type:after {
    content: '1';
    width: 20px;
    height: 20px;
    margin-left: -8px;
    font-size: 0.8em;
    text-align: center;
    background: #01c7b1;
    border-radius: 50%;
    padding: 2px;
    position: absolute;
    left: 0;
    top: 3px;
    z-index: 2;
}

.isInActive section.year section ul:first-of-type:after {
    background: #ececec !important;
}

#timeline section.year section ul li {
    margin-left: 0.5rem;
}

/*#timeline section.year section ul li:before {*/
/*    content: '·';*/
/*    margin-left: -0.5rem;*/
/*    padding-right: 0.3rem;*/
/*}*/

#timeline section.year section ul li:not(:first-child) {
    margin-top: 0.5rem;
}

#timeline section.year section ul li:nth-child(2) {
    margin-top: 0 !important;
    margin-bottom: 0.7rem;
}

#timeline section.year section ul li span.price {
    color: mediumturquoise;
    font-weight: 500;
}

#price {
    display: inline;
}

svg {
    border: 3px solid white;
    border-radius: 50%;
    box-shadow: 0 4px 6px rgba(50, 50, 93, .11), 0 1px 3px rgba(0, 0, 0, .08);
}

dl, ol, ul {
    margin-bottom: 0 !important;
}
